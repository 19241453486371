<template>
  <div class="subscribe row align-items-center justify-content-center">
    <div class="col-12 pt-0">
      <h1
        class="font-32 text-center"
        :class="$viewport.gt.md ? 'mb-5 no-wrap' : 'mb-3'"
      >
        Start your Showdigs Subscription
      </h1>
      <p class="font-17 font-weight-strong mt-5">
        Add your payment method
      </p>
      <div class="payment-method p-4 pb-5 mb-5">
        <SdBillingInfo
          :is-loading="uiFlags.isSubscribing"
          @card-created="subscribe"
        />
      </div>
    </div>
    <ElButton
      class="logout"
      size="small"
      @click="logout"
    >
      Log out
    </ElButton>
  </div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import Plan from '@/constants/Plan';
import SdBillingInfo from '@/components/common/form/SdBillingInfo';
import { showErrorNotify } from '@/utils/NotifyUtil';
import { redirectToSignIn } from '@/router';

export default {
  name: 'Subscribe',
  components: { SdBillingInfo },
  setup(props, context) {
    const store = context.root.$store;

    const uiFlags = reactive({
      isSubscribing: false,
    });
    store.commit('Ui/setTestimonial', 2);
    if (store.getters['Auth/subscription']) {
      context.root.$router.push({
        name: 'app.properties',
      });
    }

    const plan = ref(null);
    getPlan();

    return {
      uiFlags,
      plan,
      PlanConst: Plan,
      subscribe,
      logout,
    };

    async function getPlan() {
      const plans = await store.dispatch('Plan/get');
      const planFromQuery = context.root.$route.query.plan_name;
      plan.value = plans.find((plan) => {
        if (!planFromQuery) {
          return plan.is_default;
        }
        return plan.name === planFromQuery;
      });
    }

    async function subscribe(stripeSource) {
      uiFlags.isSubscribing = true;

      const business = store.getters['Auth/business'];

      if (!business || !business.address || !business.address.postal_code) {
        context.root.$router.replace({
          name: 'app.business-address',
          params: {
            stripeSourceId: stripeSource.id,
            planId: plan.value.id,
          },
        });

        return;
      }

      try {
        const subscribePayload = {
          stripe_source_id: stripeSource.id,
          plan_id: plan.value.id,
        };

        await store.dispatch('Subscription/subscribe', subscribePayload);
        await store.dispatch('Auth/me');

        context.root.$router.replace({
          name: 'app.properties',
        });
      } catch (error) {
        uiFlags.isSubscribing = false;
        showErrorNotify(context, error.message);
      }
    }

    async function logout() {
      await context.root.$store.dispatch('Auth/logout');
      redirectToSignIn(context);
    }
  },
};
</script>

<style lang="scss" scoped>
.subscribe {
  .plan {
    padding: 1rem;
    background-color: gray-color('lighter');
    border: 1px solid gray-color('light');
    border-radius: $app-border-radius-large;
  }
  .payment-method {
    border-radius: $app-border-radius;
    -webkit-box-shadow: 0 10px 100px 4px rgba(0,0,0,0.1);
    box-shadow: 0 10px 100px 4px rgba(0,0,0,0.1);
  }
  .logout {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: $--font-size-small;
    @media (max-width: 768px) {
      top: -5rem;
      right: 0;
    }
  }
}
</style>
